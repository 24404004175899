import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllEntries } from '../utils/storage';
import { useAuth } from '../contexts/AuthContext';
import { calculateBadges } from '../utils/badges';
import BadgeCard from './BadgeCard';
import type { WritingEntry } from '../lib/supabase';

export default function Badges() {
  const { t } = useTranslation();
  const [entries, setEntries] = useState<WritingEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const fetchEntries = async () => {
      try {
        const data = await getAllEntries(user?.id);
        setEntries(data);
      } catch (error) {
        console.error('Error fetching entries:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEntries();
  }, [user?.id]);

  const badges = calculateBadges(entries);

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="text-center py-12 text-gray-500">
          {t('common.loading')}
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">{t('insights.badges')}</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {badges.map(badge => (
          <BadgeCard key={badge.id} badge={badge} />
        ))}
      </div>

      {badges.length === 0 && (
        <div className="text-center py-12 text-gray-500">
          {t('badges.noBadges')}
        </div>
      )}
    </div>
  );
}