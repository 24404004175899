import React from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import type { CategoryAnalysis } from '../utils/categoryAnalysis';

interface CategoryChartProps {
  data: CategoryAnalysis[];
}

const CHART_COLORS = ['#10b981', '#34d399', '#6ee7b7', '#a7f3d0', '#d1fae5'];

export default function CategoryChart({ data }: CategoryChartProps) {
  const { t } = useTranslation();

  return (
    <div className="mt-8">
      <h3 className="text-lg font-semibold text-gray-900 mb-4">{t('stats.topCategories')}</h3>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={data} layout="vertical" barSize={25}> {/* Zmniejszono barSize */}
            <XAxis type="number" />
            <YAxis 
              dataKey="category" 
              type="category"
              width={100}
              tick={{ fontSize: 12 }}
            />
            <Tooltip 
              formatter={(value: number) => [value, t('stats.wordCount')]}
            />
            <Bar dataKey="count" radius={[0, 4, 4, 0]}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
