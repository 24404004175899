import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Header from './components/Header';
import Editor from './components/Editor';
import History from './components/History';
import Stats from './components/Stats';
import WritingInsights from './components/WritingInsights';
import Badges from './components/Badges';
import Articles from './components/Articles';

function App() {
  const [currentPage, setCurrentPage] = useState<'write' | 'history' | 'stats' | 'insights' | 'badges' | 'articles'>('write');

  return (
    <AuthProvider>
      <Router>
        <div className="min-h-screen bg-gray-50">
          <Header currentPage={currentPage} onPageChange={setCurrentPage} />
          <main className="py-8">
            <Routes>
              <Route path="/" element={<Editor />} />
              <Route path="/history" element={<History />} />
              <Route path="/stats" element={<Stats />} />
              <Route path="/insights" element={<WritingInsights />} />
              <Route path="/badges" element={<Badges />} />
              <Route path="/articles" element={<Articles />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </main>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;