import React from 'react';
import { format } from 'date-fns';
import { pl, enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import type { Badge } from '../utils/badges';

interface BadgeCardProps {
  badge: Badge;
}

export default function BadgeCard({ badge }: BadgeCardProps) {
  const { i18n } = useTranslation();
  const dateLocale = i18n.language.startsWith('pl') ? pl : enUS;
  const currentLanguage = i18n.language.startsWith('pl') ? 'pl' : 'en';

  return (
    <div className={`p-4 rounded-lg ${
      badge.earned 
        ? 'bg-emerald-50 border border-emerald-200' 
        : 'bg-gray-50 border border-gray-200'
    }`}>
      <div className="flex items-center gap-3">
        <div className="text-3xl">{badge.icon}</div>
        <div className="flex-1">
          <h3 className={`font-medium ${
            badge.earned ? 'text-emerald-900' : 'text-gray-500'
          }`}>
            {badge.title[currentLanguage]}
          </h3>
          <p className={`text-sm ${
            badge.earned ? 'text-emerald-600' : 'text-gray-400'
          }`}>
            {badge.description[currentLanguage]}
          </p>
          {badge.earned && badge.earnedDate && (
            <p className="text-xs text-emerald-500 mt-1">
              {format(new Date(badge.earnedDate), 'PP', { locale: dateLocale })}
            </p>
          )}
          {!badge.earned && badge.progress !== undefined && badge.maxProgress !== undefined && (
            <div className="mt-2">
              <div className="h-1.5 bg-gray-200 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-emerald-500 rounded-full transition-all duration-300"
                  style={{ width: `${(badge.progress / badge.maxProgress) * 100}%` }}
                />
              </div>
              <p className="text-xs text-gray-500 mt-1">
                {badge.progress} / {badge.maxProgress}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}