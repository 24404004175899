import i18n from '../i18n';
import { supabase } from '../lib/supabase';
import type { Prompt } from '../lib/supabase';

export const getRandomPrompt = async (): Promise<string> => {
  const currentLanguage = i18n.language.split('-')[0];

  try {
    const { data: prompts, error } = await supabase
      .from('prompts')
      .select('text')
      .eq('language', currentLanguage);

    if (error) throw error;

    // If no prompts found for current language, fallback to English
    if (!prompts || prompts.length === 0) {
      const { data: englishPrompts, error: englishError } = await supabase
        .from('prompts')
        .select('text')
        .eq('language', 'en');

      if (englishError) throw englishError;
      if (!englishPrompts || englishPrompts.length === 0) {
        return "What's on your mind today?"; // Fallback prompt
      }

      const randomIndex = Math.floor(Math.random() * englishPrompts.length);
      return englishPrompts[randomIndex].text;
    }

    const randomIndex = Math.floor(Math.random() * prompts.length);
    return prompts[randomIndex].text;
  } catch (error) {
    console.error('Error fetching prompts:', error);
    return "What's on your mind today?"; // Fallback prompt
  }
};