import { useState, useEffect } from 'react';
import { Analysis, analyzeText } from '../utils/textAnalysis';
import { analyzeCategoryWords } from '../utils/categoryAnalysis';
import { analyzeEmotions } from '../utils/emotionsAnalysis';

const initialStats: Analysis = {
  mindset: { type: 'balanced', percentage: 50 },
  emotion: { type: 'balanced', percentage: 50 },
  timeOrientation: { type: 'balanced', percentage: 50 },
  senses: { sight: 20, smell: 20, taste: 20, hearing: 20, touch: 20 }
};

export const useTextAnalysis = (text: string, language: string, duration: number, isOpen: boolean) => {
  const [stats, setStats] = useState<Analysis>(initialStats);
  const [categoryStats, setCategoryStats] = useState([]);
  const [emotionStats, setEmotionStats] = useState([]);
  const [wordsPerMinute, setWordsPerMinute] = useState(0);

  useEffect(() => {
    const fetchAnalysis = async () => {
      const analysis = await analyzeText(text, language);
      setStats(analysis);
      setCategoryStats(analyzeCategoryWords(text, language));
      setEmotionStats(analyzeEmotions(text, language));
      setWordsPerMinute(Math.round((text.trim().split(/\s+/).length / (duration / 60)) || 0));
    };

    if (isOpen) {
      fetchAnalysis();
    }
  }, [text, language, duration, isOpen]);

  return {
    stats,
    categoryStats,
    emotionStats,
    wordsPerMinute
  };
};