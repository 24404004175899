export default {
  header: {
    title: 'Wordenio',
    write: 'Write',
    history: 'History',
    insights: 'Insights',
    badges: 'Badges',
    articles: 'Articles'
  },
  auth: {
    title: 'Sign In / Sign Up',
    signIn: 'Sign In',
    signUp: 'Sign Up',
    signingUp: 'Signing up...',
    signOut: 'Sign Out',
    or: 'or',
    email: 'Email address',
    password: 'Password',
    alreadyHaveAccount: 'Already have an account?',
    checkEmail: 'Please check your email for the confirmation link.'
  },
  common: {
    loading: 'Loading...'
  },
  editor: {
    date: '{{date}}',
    words: 'words',
    of: 'of',
    goalReached: '🎉 Goal reached!',
    streak: '🔥 {{count}} day streak!',
    streakPlural: '🔥 {{count}} days streak!',
    settings: 'Settings',
    getPrompt: 'Get Prompt',
    todayStats: "Today's Stats",
    save: 'Save',
    feeling: 'How are you feeling?',
    startWriting: 'Create every day - 250 words is the perfect starting point...'
  },
  settings: {
    title: 'Settings',
    dailyGoal: 'Daily Word Goal',
    cancel: 'Cancel',
    saveChanges: 'Save Changes',
    language: 'Language'
  },
  stats: {
    title: "Today's Writing Analysis",
    noText: 'No text to analyze. Start writing to see your stats!',
    writingSpeed: 'Writing Speed',
    wordsPerMinute: '{{count}} words/minute',
    topCategories: 'What are you writing about',
    topEmotions: 'What do you feel',
    wordCount: 'Word Count',
    mindset: 'Mindset',
    emotionalTone: 'Emotional Tone',
    timeOrientation: 'Time Orientation',
    senses: 'Sensory Focus',
    types: {
      analytical: 'Analytical',
      creative: 'Creative',
      positive: 'Positive',
      contemplative: 'Contemplative',
      futureFocused: 'Future-focused',
      pastReflective: 'Past-reflective',
      sight: 'Sight',
      smell: 'Smell',
      taste: 'Taste',
      hearing: 'Hearing',
      touch: 'Touch'
    }
  },
  history: {
    title: 'Writing History',
    noEntries: 'No writing entries yet. Start writing to see your history!',
    delete: 'Delete entry',
    confirmDelete: 'Are you sure you want to delete this entry? This action cannot be undone.'
  },
  insights: {
    title: 'Writing Insights',
    totalWords: 'Total Words',
    wordsWritten: 'Words Written',
    averageWords: 'Average Words',
    wordsPerDay: 'Words per Day',
    longestStreak: 'Longest Streak',
    daysInRow: 'Days in a Row',
    avgWritingTime: 'Avg. Writing Time',
    minutesPerDay: 'Minutes per Day',
    weeklyProgress: 'Weekly Progress',
    words: 'Words',
    dailyGoal: 'Daily Goal',
    badges: 'Achievements'
  },
  badges: {
    noBadges: 'No badges earned yet. Start writing to earn badges!'
  }
}