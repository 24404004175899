import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import ReactMarkdown from 'react-markdown';
import { Calendar, Tag } from 'lucide-react';

interface Article {
  id: string;
  title: string;
  content: string;
  author: string;
  category: string;
  created_at: string;
}

export default function Articles() {
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    fetchArticles();
  }, [selectedCategory]);

  const fetchArticles = async () => {
    try {
      let query = supabase
        .from('articles')
        .select('*')
        .eq('published', true)
        .order('created_at', { ascending: false });

      if (selectedCategory) {
        query = query.eq('category', selectedCategory);
      }

      const { data, error } = await query;

      if (error) {
        console.error('Error fetching articles:', error);
        return;
      }

      setArticles(data || []);

      // Get unique categories
      const uniqueCategories = [...new Set(data?.map(article => article.category).filter(Boolean))];
      setCategories(uniqueCategories);
    } catch (error) {
      console.error('Error fetching articles:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="text-center py-12 text-gray-500">Ładowanie artykułów...</div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Artykuły</h1>

      {/* Categories */}
      {categories.length > 0 && (
        <div className="mb-8">
          <div className="flex flex-wrap gap-2">
            <button
              onClick={() => setSelectedCategory(null)}
              className={`flex items-center gap-2 px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                !selectedCategory
                  ? 'bg-emerald-100 text-emerald-800'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              Wszystkie
            </button>
            {categories.map(category => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`flex items-center gap-2 px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                  selectedCategory === category
                    ? 'bg-emerald-100 text-emerald-800'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
              >
                <Tag size={16} />
                {category}
              </button>
            ))}
          </div>
        </div>
      )}

      <div className="space-y-12">
        {articles.map((article) => (
          <article key={article.id} className="bg-white shadow rounded-lg overflow-hidden">
            <div className="p-6">
              <h2 className="text-2xl font-bold text-gray-900 mb-2">{article.title}</h2>
              <div className="flex items-center gap-4 text-sm text-gray-500 mb-6">
                <span className="flex items-center gap-2">
                  <Calendar size={16} />
                  <time dateTime={article.created_at}>
                    {new Date(article.created_at).toLocaleDateString()}
                  </time>
                </span>
                {article.author && (
                  <span>przez {article.author}</span>
                )}
                {article.category && (
                  <span className="flex items-center gap-2">
                    <Tag size={16} />
                    {article.category}
                  </span>
                )}
              </div>
              <div className="prose max-w-none">
                <ReactMarkdown>{article.content}</ReactMarkdown>
              </div>
            </div>
          </article>
        ))}

        {articles.length === 0 && (
          <div className="text-center py-12 text-gray-500">
            {selectedCategory 
              ? `Brak artykułów w kategorii "${selectedCategory}".`
              : 'Brak artykułów do wyświetlenia.'}
          </div>
        )}
      </div>
    </div>
  );
}