import { normalizePolishText } from './textAnalysis';
import { getWordLists } from '../lib/supabase';

interface CategoryWords {
  [key: string]: string[];
}

export interface CategoryAnalysis {
  category: string;
  count: number;
  percentage: number;
}

export const analyzeCategoryWords = async (text: string, language: string): Promise<CategoryAnalysis[]> => {
  const normalizedText = language.startsWith('pl')
    ? normalizePolishText(text.toLowerCase())
    : text.toLowerCase();
  const words = normalizedText.split(/\s+/);
  const lists = await getWordLists(language.startsWith('pl') ? 'pl' : 'en');

  const categoryCounts = Object.entries(lists).map(([category, wordList]) => {
    const count = words.filter(word => wordList.includes(word)).length;
    return { category, count };
  });

  const totalWords = categoryCounts.reduce((sum, { count }) => sum + count, 0) || 1;

  return categoryCounts
    .map(({ category, count }) => ({
      category,
      count,
      percentage: (count / totalWords) * 100
    }))
    .sort((a, b) => b.count - a.count)
    .slice(0, 5); // Get top 5
};