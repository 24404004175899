import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import { pl, enUS } from 'date-fns/locale';
import { getAllEntries } from '../utils/storage';
import { Clock, FileText, X, Trash2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext';
import type { WritingEntry } from '../lib/supabase';
import { supabase } from '../lib/supabase';

export default function History() {
  const [entries, setEntries] = useState<WritingEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const dateLocale = i18n.language.startsWith('pl') ? pl : enUS;
  const [selectedEntry, setSelectedEntry] = useState<WritingEntry | null>(null);
  const { user } = useAuth();
  
  const fetchEntries = async () => {
    try {
      const data = await getAllEntries(user?.id);
      setEntries(data);
    } catch (error) {
      console.error('Failed to fetch entries:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEntries();
  }, [user?.id]);
  
  const formatDuration = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}m ${secs}s`;
  };

  const handleDelete = async (entryId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    if (!user || !entryId) return;

    if (window.confirm(t('history.confirmDelete'))) {
      try {
        const { error } = await supabase
          .from('entries')
          .delete()
          .eq('id', entryId)
          .eq('user_id', user.id);

        if (error) throw error;
        
        // Refresh entries after deletion
        await fetchEntries();
        
        // Close modal if the deleted entry was selected
        if (selectedEntry?.id === entryId) {
          setSelectedEntry(null);
        }
      } catch (error) {
        console.error('Failed to delete entry:', error);
      }
    }
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="text-center py-12 text-gray-500">
          {t('common.loading')}
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">{t('history.title')}</h1>
      
      <div className="space-y-4">
        {entries.map((entry) => (
          <div
            key={entry.id || entry.date}
            className="bg-white rounded-lg shadow p-6 hover:shadow-md transition-shadow cursor-pointer"
            onClick={() => setSelectedEntry(entry)}
          >
            <div className="flex items-start justify-between mb-4">
              <div>
                <h2 className="text-xl font-semibold text-gray-900">
                  {format(parseISO(entry.date), 'd MMMM yyyy', { locale: dateLocale })}
                </h2>
                <div className="flex items-center gap-4 mt-2 text-gray-600">
                  <span className="flex items-center gap-1">
                    <FileText size={16} />
                    {entry.word_count} {t('editor.words')}
                  </span>
                  <span className="flex items-center gap-1">
                    <Clock size={16} />
                    {formatDuration(entry.duration)}
                  </span>
                  {entry.mood && (
                    <span>{entry.mood}</span>
                  )}
                </div>
              </div>
              {user && entry.id && (
                <button
                  onClick={(e) => handleDelete(entry.id, e)}
                  className="p-2 text-gray-400 hover:text-red-600 rounded-full hover:bg-red-50 transition-colors"
                  title={t('history.delete')}
                >
                  <Trash2 size={18} />
                </button>
              )}
            </div>
            
            <p className="text-gray-600 line-clamp-3">
              {entry.text}
            </p>
          </div>
        ))}

        {entries.length === 0 && (
          <div className="text-center py-12 text-gray-500">
            {t('history.noEntries')}
          </div>
        )}
      </div>

      {/* Entry Detail Modal */}
      {selectedEntry && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-xl shadow-xl max-w-3xl w-full max-h-[90vh] overflow-y-auto">
            <div className="sticky top-0 bg-white border-b border-gray-200 px-6 py-4 flex items-center justify-between">
              <div>
                <h2 className="text-2xl font-bold text-gray-900">
                  {format(parseISO(selectedEntry.date), 'd MMMM yyyy', { locale: dateLocale })}
                </h2>
                <div className="flex items-center gap-4 mt-2 text-gray-600">
                  <span className="flex items-center gap-1">
                    <FileText size={16} />
                    {selectedEntry.word_count} {t('editor.words')}
                  </span>
                  <span className="flex items-center gap-1">
                    <Clock size={16} />
                    {formatDuration(selectedEntry.duration)}
                  </span>
                  {selectedEntry.mood && (
                    <span>{selectedEntry.mood}</span>
                  )}
                </div>
              </div>
              <div className="flex gap-2">
                <button
                  onClick={() => setSelectedEntry(null)}
                  className="p-2 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
                >
                  <X size={20} />
                </button>
              </div>
            </div>
            
            <div className="p-6">
              {selectedEntry.prompt && (
                <div className="mb-6 p-4 bg-amber-50 border border-amber-200 rounded-lg">
                  <p className="text-amber-800 font-medium">{selectedEntry.prompt}</p>
                </div>
              )}
              
              <div className="prose max-w-none">
                <p className="whitespace-pre-wrap">{selectedEntry.text}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}