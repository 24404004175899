import React, { useState, useEffect, useRef, useCallback } from 'react';
import { format } from 'date-fns';
import { pl, enUS } from 'date-fns/locale';
import { Timer, Lightbulb } from 'lucide-react';
import confetti from 'canvas-confetti';
import { saveEntry, getCurrentStreak, getUserSettings, getTodayEntry } from '../utils/storage';
import { getRandomPrompt } from '../utils/prompts';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const AUTOSAVE_INTERVAL = 15000; // 15 seconds

export default function Editor() {
  const [text, setText] = useState('');
  const navigate = useNavigate();
  const [wordCount, setWordCount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [streak, setStreak] = useState(0);
  const [mood, setMood] = useState<string>();
  const [currentPrompt, setCurrentPrompt] = useState('');
  const [showPrompt, setShowPrompt] = useState(false);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const hasReachedGoalRef = useRef(false);
  const startTimeRef = useRef(Date.now());
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const settings = getUserSettings(user?.id);
  const TARGET_WORDS = settings.dailyGoal;
  const autoSaveIntervalRef = useRef<NodeJS.Timeout>();
  const lastSaveRef = useRef<string>(text);

  const moods = {
    en: ['😊 Happy', '😌 Calm', '🤔 Thoughtful', '😤 Frustrated', '😢 Sad'],
    pl: ['😊 Szczęśliwy/a', '😌 Spokojny/a', '🤔 Zamyślony/a', '😤 Sfrustrowany/a', '😢 Smutny/a']
  };

  // Load today's entry when component mounts
  useEffect(() => {
    const loadTodayEntry = async () => {
      const todayEntry = await getTodayEntry(user?.id);
      if (todayEntry) {
        setText(todayEntry.text || '');
        setDuration(todayEntry.duration || 0);
        setMood(todayEntry.mood);
        if (todayEntry.prompt) {
          setCurrentPrompt(todayEntry.prompt);
          setShowPrompt(true);
        }
        startTimeRef.current = Date.now() - ((todayEntry.duration || 0) * 1000);
        lastSaveRef.current = todayEntry.text || '';
      }
    };
    loadTodayEntry();
  }, [user?.id]);

  // Update duration timer
  useEffect(() => {
    let timer: NodeJS.Timer;
    if (isTimerActive) {
      timer = setInterval(() => {
        setDuration(Math.floor((Date.now() - startTimeRef.current) / 1000));
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isTimerActive]);

  // Update streak
  useEffect(() => {
    const updateStreak = async () => {
      const currentStreak = await getCurrentStreak(user?.id);
      setStreak(currentStreak);
    };
    updateStreak();
  }, [user?.id]);

  const handleSave = useCallback(async () => {
    if (!text || text === lastSaveRef.current) return;
    
    try {
      await saveEntry(text, duration, mood, currentPrompt, user?.id);
      lastSaveRef.current = text;
      const currentStreak = await getCurrentStreak(user?.id);
      setStreak(currentStreak);
    } catch (error) {
      console.error('Failed to save entry:', error);
    }
  }, [text, duration, mood, currentPrompt, user?.id]);

  // Auto-save every 15 seconds
  useEffect(() => {
    if (autoSaveIntervalRef.current) {
      clearInterval(autoSaveIntervalRef.current);
    }

    autoSaveIntervalRef.current = setInterval(handleSave, AUTOSAVE_INTERVAL);

    return () => {
      if (autoSaveIntervalRef.current) {
        clearInterval(autoSaveIntervalRef.current);
      }
    };
  }, [handleSave]);

  // Save before window/tab closes
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (text && text !== lastSaveRef.current) {
        handleSave();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      handleBeforeUnload();
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [text, handleSave]);

  const triggerConfetti = () => {
    const duration = 3000;
    const end = Date.now() + duration;

    const frame = () => {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: ['#059669', '#34d399', '#6ee7b7']
      });
      
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: ['#059669', '#34d399', '#6ee7b7']
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    };

    frame();
  };

  useEffect(() => {
    if (!text) return;
    
    const words = text.trim().split(/\s+/).filter(word => word.length > 0);
    const count = words.length;
    setWordCount(count);
    setProgress((count / TARGET_WORDS) * 100);

    if (count >= TARGET_WORDS && !hasReachedGoalRef.current) {
      hasReachedGoalRef.current = true;
      setIsTimerActive(false);
      triggerConfetti();
    } else if (count < TARGET_WORDS) {
      hasReachedGoalRef.current = false;
      setIsTimerActive(true);
    }
  }, [text, TARGET_WORDS]);

  const formatDuration = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const handleNewPrompt = async () => {
    const prompt = await getRandomPrompt();
    setCurrentPrompt(prompt);
    setShowPrompt(true);
  };

  const currentMoods = i18n.language.startsWith('pl') ? moods.pl : moods.en;
  const dateLocale = i18n.language.startsWith('pl') ? pl : enUS;

  return (
    <div className="w-full max-w-4xl mx-auto px-4">
      <div className="flex items-center justify-between mb-6">
        <div className="space-y-1">
          <h2 className="text-2xl font-semibold text-gray-800">
            {format(new Date(), 'd MMMM yyyy', { locale: dateLocale })}
          </h2>
          <div className="flex items-center gap-4 text-gray-600">
            <span>
              {wordCount} {t('editor.of')} {TARGET_WORDS} {t('editor.words')}
              {wordCount >= TARGET_WORDS && (
                <span className="ml-2 text-emerald-600 font-medium">
                  {t('editor.goalReached')}
                </span>
              )}
            </span>
            <span className="flex items-center gap-1">
              <Timer size={16} />
              {formatDuration(duration)}
            </span>
            {streak > 0 && (
              <span className="text-orange-500">
                {t('editor.streak', { count: streak })}
              </span>
            )}
          </div>
        </div>
        <div className="flex gap-3">
          <button
            onClick={handleNewPrompt}
            disabled={!!currentPrompt}
            className={`flex items-center gap-2 px-4 py-2 bg-white border border-gray-300 text-gray-700 rounded-lg transition-colors ${
              currentPrompt ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'
            }`}
          >
            <Lightbulb size={18} />
            {t('editor.getPrompt')}
          </button>
          {wordCount >= TARGET_WORDS && (
            <button
              onClick={() => navigate('/stats', { state: { text, duration, mood } })}
              className="flex items-center gap-2 px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
            >
              <Timer size={18} />
              {t('editor.todayStats')}
            </button>
          )}
        </div>
      </div>

      {showPrompt && currentPrompt && (
        <div className="mb-6 p-4 bg-amber-50 border border-amber-200 rounded-lg">
          <p className="text-amber-800 font-medium">{currentPrompt}</p>
        </div>
      )}

      <div className="mb-6">
        <div className="flex flex-col sm:flex-row sm:items-center gap-4 mb-2">
          <span className="text-sm font-medium text-gray-700">{t('editor.feeling')}</span>
          <div className="flex flex-wrap gap-2">
            {currentMoods.map((m) => (
              <button
                key={m}
                onClick={() => setMood(m)}
                className={`px-4 py-2 rounded-full text-sm transition-colors ${
                  mood === m
                    ? 'bg-emerald-100 text-emerald-700'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
              >
                {m}
              </button>
            ))}
          </div>
        </div>
        <div className="bg-gray-200 rounded-full h-2">
          <div
            className="bg-emerald-600 h-2 rounded-full transition-all duration-300"
            style={{ width: `${Math.min(progress, 100)}%` }}
          />
        </div>
      </div>

      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="w-full h-[calc(100vh-16rem)] p-6 bg-white rounded-lg shadow-lg border border-gray-200 focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 outline-none resize-none writing-area" 
        placeholder={t('editor.startWriting')}
      />
    </div>
  );
}