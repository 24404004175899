import { WritingEntry } from '../lib/supabase';

export interface Badge {
  id: string;
  title: {
    en: string;
    pl: string;
  };
  description: {
    en: string;
    pl: string;
  };
  icon: string;
  earned: boolean;
  earnedDate?: string;
  progress?: number;
  maxProgress?: number;
}

export const calculateBadges = (entries: WritingEntry[]): Badge[] => {
  const badges: Badge[] = [
    // Existing badges
    {
      id: 'first-word',
      title: {
        en: 'First Word',
        pl: 'Pierwsze Słowo'
      },
      description: {
        en: 'Wrote your first word',
        pl: 'Napisałeś/aś swoje pierwsze słowo'
      },
      icon: '✍️',
      earned: entries.length > 0,
      earnedDate: entries[0]?.created_at
    },
    {
      id: 'two-days-streak',
      title: {
        en: 'Getting Started',
        pl: 'Początek Drogi'
      },
      description: {
        en: 'Wrote for 2 days in a row',
        pl: 'Pisałeś/aś przez 2 dni z rzędu'
      },
      icon: '🔥',
      earned: false,
      earnedDate: undefined
    },
    {
      id: 'five-days-streak',
      title: {
        en: 'Consistent Writer',
        pl: 'Regularny Pisarz'
      },
      description: {
        en: 'Wrote for 5 days in a row',
        pl: 'Pisałeś/aś przez 5 dni z rzędu'
      },
      icon: '⭐',
      earned: false,
      earnedDate: undefined
    },
    {
      id: 'ten-days-streak',
      title: {
        en: 'Writing Master',
        pl: 'Mistrz Pisania'
      },
      description: {
        en: 'Wrote for 10 days in a row',
        pl: 'Pisałeś/aś przez 10 dni z rzędu'
      },
      icon: '👑',
      earned: false,
      earnedDate: undefined
    },
    // New milestone badges
    {
      id: 'twenty-days-streak',
      title: {
        en: 'Writing Legend',
        pl: 'Legenda Pisania'
      },
      description: {
        en: 'Wrote for 20 days in a row',
        pl: 'Pisałeś/aś przez 20 dni z rzędu'
      },
      icon: '🌟',
      earned: false,
      earnedDate: undefined
    },
    {
      id: 'thirty-days-streak',
      title: {
        en: 'Writing Virtuoso',
        pl: 'Wirtuoz Pisania'
      },
      description: {
        en: 'Wrote for 30 days in a row',
        pl: 'Pisałeś/aś przez 30 dni z rzędu'
      },
      icon: '🏆',
      earned: false,
      earnedDate: undefined
    },
    // Word count badges
    {
      id: 'word-count-1000',
      title: {
        en: 'Wordsmith',
        pl: 'Władca Słów'
      },
      description: {
        en: 'Wrote 1,000 words in total',
        pl: 'Napisałeś/aś łącznie 1000 słów'
      },
      icon: '📚',
      earned: false,
      earnedDate: undefined
    },
    {
      id: 'word-count-10000',
      title: {
        en: 'Prolific Author',
        pl: 'Płodny Autor'
      },
      description: {
        en: 'Wrote 10,000 words in total',
        pl: 'Napisałeś/aś łącznie 10000 słów'
      },
      icon: '📖',
      earned: false,
      earnedDate: undefined
    },
    {
      id: 'word-count-50000',
      title: {
        en: 'Writing Machine',
        pl: 'Maszyna do Pisania'
      },
      description: {
        en: 'Wrote 50,000 words in total',
        pl: 'Napisałeś/aś łącznie 50000 słów'
      },
      icon: '📝',
      earned: false,
      earnedDate: undefined
    },
    // Time-based badges
    {
      id: 'morning-writer',
      title: {
        en: 'Early Bird',
        pl: 'Ranny Ptaszek'
      },
      description: {
        en: 'Wrote before 9 AM',
        pl: 'Pisałeś/aś przed 9 rano'
      },
      icon: '🌅',
      earned: false,
      earnedDate: undefined
    },
    {
      id: 'night-owl',
      title: {
        en: 'Night Owl',
        pl: 'Nocny Marek'
      },
      description: {
        en: 'Wrote after 10 PM',
        pl: 'Pisałeś/aś po 22:00'
      },
      icon: '🌙',
      earned: false,
      earnedDate: undefined
    },
    {
      id: 'lunch-break-writer',
      title: {
        en: 'Lunch Break Writer',
        pl: 'Pisarz z Przerwy'
      },
      description: {
        en: 'Wrote during lunch hours (12-2 PM)',
        pl: 'Pisałeś/aś w porze lunchu (12-14)'
      },
      icon: '🍽️',
      earned: false,
      earnedDate: undefined
    },
    // Special achievements
    {
      id: 'weekend-warrior',
      title: {
        en: 'Weekend Warrior',
        pl: 'Weekendowy Wojownik'
      },
      description: {
        en: 'Wrote on both Saturday and Sunday',
        pl: 'Pisałeś/aś w sobotę i niedzielę'
      },
      icon: '🎨',
      earned: false,
      earnedDate: undefined
    },
    {
      id: 'holiday-writer',
      title: {
        en: 'Holiday Spirit',
        pl: 'Świąteczny Duch'
      },
      description: {
        en: 'Wrote on a major holiday',
        pl: 'Pisałeś/aś w święto'
      },
      icon: '🎄',
      earned: false,
      earnedDate: undefined
    },
    // Performance badges
    {
      id: 'marathon-writer',
      title: {
        en: 'Marathon Writer',
        pl: 'Maratończyk Pisania'
      },
      description: {
        en: 'Wrote for over 1 hour in one session',
        pl: 'Pisałeś/aś przez ponad godzinę w jednej sesji'
      },
      icon: '⏱️',
      earned: false,
      earnedDate: undefined
    },
    {
      id: 'ultra-marathon',
      title: {
        en: 'Ultra Marathon',
        pl: 'Ultra Maraton'
      },
      description: {
        en: 'Wrote for over 2 hours in one session',
        pl: 'Pisałeś/aś przez ponad 2 godziny w jednej sesji'
      },
      icon: '⌛',
      earned: false,
      earnedDate: undefined
    },
    {
      id: 'speed-demon',
      title: {
        en: 'Speed Demon',
        pl: 'Demon Prędkości'
      },
      description: {
        en: 'Wrote over 500 words in 30 minutes',
        pl: 'Napisałeś/aś ponad 500 słów w 30 minut'
      },
      icon: '⚡',
      earned: false,
      earnedDate: undefined
    },
    {
      id: 'lightning-fingers',
      title: {
        en: 'Lightning Fingers',
        pl: 'Błyskawiczne Palce'
      },
      description: {
        en: 'Wrote over 1000 words in 1 hour',
        pl: 'Napisałeś/aś ponad 1000 słów w godzinę'
      },
      icon: '🌩️',
      earned: false,
      earnedDate: undefined
    },
    // Consistency badges
    {
      id: 'daily-goal-master',
      title: {
        en: 'Goal Crusher',
        pl: 'Pogromca Celów'
      },
      description: {
        en: 'Reached daily goal 5 times',
        pl: 'Osiągnąłeś/aś dzienny cel 5 razy'
      },
      icon: '🎯',
      earned: false,
      earnedDate: undefined
    },
    {
      id: 'goal-streak',
      title: {
        en: 'Goal Streak Master',
        pl: 'Mistrz Celów'
      },
      description: {
        en: 'Reached daily goal 10 days in a row',
        pl: 'Osiągnąłeś/aś dzienny cel 10 dni z rzędu'
      },
      icon: '🎪',
      earned: false,
      earnedDate: undefined
    }
  ];

  // Calculate achievements...
  // (Rest of the calculation logic remains the same, just add checks for new badges)

  // Sort entries by date
  const sortedEntries = [...entries].sort((a, b) => 
    new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  let currentStreak = 1;
  let maxStreak = 1;
  let lastDate = sortedEntries[0]?.date;
  let twoDaysDate: string | undefined;
  let fiveDaysDate: string | undefined;
  let tenDaysDate: string | undefined;
  let twentyDaysDate: string | undefined;
  let thirtyDaysDate: string | undefined;

  // Calculate total words
  const totalWords = entries.reduce((sum, entry) => sum + (entry.word_count || 0), 0);

  // Calculate goal achievements
  const goalAchievements = entries.filter(entry => 
    (entry.word_count || 0) >= (entry.daily_goal || 250)
  ).length;

  // Calculate streaks and check for weekend writing
  let hasWrittenOnSaturday = false;
  let hasWrittenOnSunday = false;
  let currentGoalStreak = 0;
  let maxGoalStreak = 0;
  
  for (let i = 1; i < sortedEntries.length; i++) {
    const entry = sortedEntries[i];
    const currentDate = new Date(entry.date);
    const previousDate = new Date(lastDate);
    previousDate.setDate(previousDate.getDate() + 1);

    // Check weekend writing
    if (currentDate.getDay() === 6) hasWrittenOnSaturday = true;
    if (currentDate.getDay() === 0) hasWrittenOnSunday = true;

    // Check goal streak
    if (entry.word_count >= (entry.daily_goal || 250)) {
      currentGoalStreak++;
      maxGoalStreak = Math.max(maxGoalStreak, currentGoalStreak);
    } else {
      currentGoalStreak = 0;
    }

    if (currentDate.getTime() === previousDate.getTime() && entry.word_count >= (entry.daily_goal || 250)) {
      currentStreak++;
      if (currentStreak === 2 && !twoDaysDate) {
        twoDaysDate = entry.created_at;
      }
      if (currentStreak === 5 && !fiveDaysDate) {
        fiveDaysDate = entry.created_at;
      }
      if (currentStreak === 10 && !tenDaysDate) {
        tenDaysDate = entry.created_at;
      }
      if (currentStreak === 20 && !twentyDaysDate) {
        twentyDaysDate = entry.created_at;
      }
      if (currentStreak === 30 && !thirtyDaysDate) {
        thirtyDaysDate = entry.created_at;
      }
      maxStreak = Math.max(maxStreak, currentStreak);
    } else {
      currentStreak = 1;
    }
    lastDate = entry.date;
  }

  // Update streak badges
  const streakBadges = [
    { id: 'two-days-streak', days: 2, date: twoDaysDate },
    { id: 'five-days-streak', days: 5, date: fiveDaysDate },
    { id: 'ten-days-streak', days: 10, date: tenDaysDate },
    { id: 'twenty-days-streak', days: 20, date: twentyDaysDate },
    { id: 'thirty-days-streak', days: 30, date: thirtyDaysDate }
  ];

  streakBadges.forEach(({ id, days, date }) => {
    const badge = badges.find(b => b.id === id);
    if (badge) {
      badge.earned = maxStreak >= days;
      badge.earnedDate = date;
    }
  });

  // Update word count badges
  const wordCountBadges = [
    { id: 'word-count-1000', count: 1000 },
    { id: 'word-count-10000', count: 10000 },
    { id: 'word-count-50000', count: 50000 }
  ];

  wordCountBadges.forEach(({ id, count }) => {
    const badge = badges.find(b => b.id === id);
    if (badge) {
      badge.earned = totalWords >= count;
      badge.progress = Math.min(totalWords, count);
      badge.maxProgress = count;
      if (badge.earned) {
        badge.earnedDate = entries[entries.length - 1]?.created_at;
      }
    }
  });

  // Update goal achievement badges
  const goalMasterBadge = badges.find(b => b.id === 'daily-goal-master');
  if (goalMasterBadge) {
    goalMasterBadge.earned = goalAchievements >= 5;
    goalMasterBadge.progress = Math.min(goalAchievements, 5);
    goalMasterBadge.maxProgress = 5;
    if (goalMasterBadge.earned) {
      goalMasterBadge.earnedDate = entries[entries.length - 1]?.created_at;
    }
  }

  const goalStreakBadge = badges.find(b => b.id === 'goal-streak');
  if (goalStreakBadge) {
    goalStreakBadge.earned = maxGoalStreak >= 10;
    goalStreakBadge.progress = Math.min(maxGoalStreak, 10);
    goalStreakBadge.maxProgress = 10;
    if (goalStreakBadge.earned) {
      goalStreakBadge.earnedDate = entries[entries.length - 1]?.created_at;
    }
  }

  // Check for time-based achievements
  for (const entry of entries) {
    const createdAt = new Date(entry.created_at);
    const hours = createdAt.getHours();

    // Early Bird badge
    const earlyBirdBadge = badges.find(b => b.id === 'morning-writer');
    if (earlyBirdBadge && hours < 9) {
      earlyBirdBadge.earned = true;
      earlyBirdBadge.earnedDate = entry.created_at;
    }

    // Night Owl badge
    const nightOwlBadge = badges.find(b => b.id === 'night-owl');
    if (nightOwlBadge && hours >= 22) {
      nightOwlBadge.earned = true;
      nightOwlBadge.earnedDate = entry.created_at;
    }

    // Lunch Break Writer badge
    const lunchBreakBadge = badges.find(b => b.id === 'lunch-break-writer');
    if (lunchBreakBadge && hours >= 12 && hours < 14) {
      lunchBreakBadge.earned = true;
      lunchBreakBadge.earnedDate = entry.created_at;
    }

    // Marathon Writer badge
    const marathonBadge = badges.find(b => b.id === 'marathon-writer');
    if (marathonBadge && (entry.duration || 0) >= 3600) {
      marathonBadge.earned = true;
      marathonBadge.earnedDate = entry.created_at;
    }

    // Ultra Marathon badge
    const ultraMarathonBadge = badges.find(b => b.id === 'ultra-marathon');
    if (ultraMarathonBadge && (entry.duration || 0) >= 7200) {
      ultraMarathonBadge.earned = true;
      ultraMarathonBadge.earnedDate = entry.created_at;
    }

    // Speed Demon badge
    const speedDemonBadge = badges.find(b => b.id === 'speed-demon');
    if (speedDemonBadge && (entry.duration || 0) <= 1800 && (entry.word_count || 0) >= 500) {
      speedDemonBadge.earned = true;
      speedDemonBadge.earnedDate = entry.created_at;
    }

    // Lightning Fingers badge
    const lightningFingersBadge = badges.find(b => b.id === 'lightning-fingers');
    if (lightningFingersBadge && (entry.duration || 0) <= 3600 && (entry.word_count || 0) >= 1000) {
      lightningFingersBadge.earned = true;
      lightningFingersBadge.earnedDate = entry.created_at;
    }

    // Holiday Writer badge (simple check for major holidays)
    const holidayWriterBadge = badges.find(b => b.id === 'holiday-writer');
    if (holidayWriterBadge) {
      const month = createdAt.getMonth();
      const date = createdAt.getDate();
      const isHoliday = (month === 11 && date === 25) || // Christmas
                       (month === 0 && date === 1) ||   // New Year
                       (month === 11 && date === 31);   // New Year's Eve
      if (isHoliday) {
        holidayWriterBadge.earned = true;
        holidayWriterBadge.earnedDate = entry.created_at;
      }
    }
  }

  // Weekend Warrior badge
  const weekendWarriorBadge = badges.find(b => b.id === 'weekend-warrior');
  if (weekendWarriorBadge && hasWrittenOnSaturday && hasWrittenOnSunday) {
    weekendWarriorBadge.earned = true;
    weekendWarriorBadge.earnedDate = entries[entries.length - 1]?.created_at;
  }

  return badges;
};