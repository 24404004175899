import { normalizePolishText } from './textAnalysis';

interface EmotionWords {
  [key: string]: string[];
}

const emotionWords: Record<string, EmotionWords> = {
  en: {
    Joy: ['happy', 'joy', 'delight', 'excited', 'cheerful', 'glad', 'pleased', 'thrilled'],
    Sadness: ['sad', 'unhappy', 'depressed', 'down', 'gloomy', 'miserable', 'heartbroken'],
    Fear: ['afraid', 'scared', 'fearful', 'terrified', 'anxious', 'worried', 'nervous'],
    Love: ['love', 'adore', 'cherish', 'affection', 'fondness', 'caring', 'tender'],
    Anger: ['angry', 'mad', 'furious', 'outraged', 'irritated', 'annoyed', 'frustrated'],
    Overwhelm: ['overwhelmed', 'stressed', 'pressured', 'burdened', 'swamped', 'overloaded'],
    Surprise: ['surprised', 'amazed', 'astonished', 'shocked', 'stunned', 'startled'],
    Shame: ['ashamed', 'embarrassed', 'humiliated', 'guilty', 'disgraced', 'mortified'],
    Guilt: ['guilty', 'remorseful', 'regretful', 'sorry', 'apologetic', 'conscience-stricken'],
    Relief: ['relieved', 'relaxed', 'reassured', 'unburdened', 'comfortable', 'at ease'],
    Jealousy: ['jealous', 'envious', 'resentful', 'covetous', 'possessive'],
    Gratitude: ['grateful', 'thankful', 'appreciative', 'blessed', 'indebted'],
    Pride: ['proud', 'accomplished', 'confident', 'satisfied', 'successful'],
    Peace: ['peaceful', 'calm', 'serene', 'tranquil', 'content', 'harmonious'],
    Longing: ['missing', 'yearning', 'nostalgic', 'homesick', 'wistful'],
    Hope: ['hopeful', 'optimistic', 'encouraged', 'confident', 'positive'],
    Disappointment: ['disappointed', 'letdown', 'disheartened', 'discouraged'],
    Empathy: ['understanding', 'compassionate', 'sympathetic', 'sensitive'],
    Frustration: ['frustrated', 'exasperated', 'impatient', 'annoyed'],
    Loneliness: ['lonely', 'isolated', 'abandoned', 'solitary', 'alone'],
    Infatuation: ['infatuated', 'smitten', 'enchanted', 'captivated', 'enamored']
  },
  pl: {
    'Radość': ['radosc', 'szczescie', 'zadowolenie', 'wesoly', 'radosny', 'szczesliwy'],
    'Smutek': ['smutek', 'zal', 'przygnebienie', 'smutny', 'przygnebiajacy'],
    'Strach': ['strach', 'lek', 'obawa', 'przerazenie', 'niepewnosc', 'niepokój'],
    'Miłość': ['milosc', 'uwielbienie', 'czulosc', 'kochanie', 'zakochanie'],
    'Złość': ['zlosc', 'gniew', 'wscieklosc', 'irytacja', 'zdenerwowanie'],
    'Przytłoczenie': ['przytloczenie', 'stres', 'presja', 'ciezar', 'przemeczenie'],
    'Zaskoczenie': ['zaskoczenie', 'zdumienie', 'zdziwienie', 'szok', 'niedowierzanie'],
    'Wstyd': ['wstyd', 'zazenowanie', 'upokorzenie', 'kompromitacja'],
    'Wina': ['wina', 'wyrzuty sumienia', 'skrucha', 'zal za grzechy'],
    'Ulga': ['ulga', 'odprezenie', 'spokój', 'wytchnienie', 'komfort'],
    'Zazdrość': ['zazdrosc', 'zawisc', 'konkurencja', 'rywalizacja'],
    'Wdzięczność': ['wdziecznosc', 'podziekowanie', 'docenianie'],
    'Duma': ['duma', 'satysfakcja', 'spelnienie', 'osiagniecie'],
    'Spokój': ['spokoj', 'harmonia', 'równowaga', 'ukojenie', 'cisza'],
    'Tęsknota': ['tesknota', 'brak', 'nostalgia', 'pragnienie'],
    'Nadzieja': ['nadzieja', 'optymizm', 'oczekiwanie', 'wiara'],
    'Rozczarowanie': ['rozczarowanie', 'zawiedzenie', 'niedosyt'],
    'Empatia': ['empatia', 'wspolczucie', 'zrozumienie'],
    'Frustracja': ['frustracja', 'bezsilnosc', 'niecierpliwosc'],
    'Samotność': ['samotnosc', 'odosobnienie', 'izolacja'],
    'Zauroczenie': ['zauroczenie', 'zainteresowanie', 'fascynacja']
  }
};

export interface EmotionAnalysis {
  emotion: string;
  count: number;
  percentage: number;
}

export const analyzeEmotions = (text: string, language: string): EmotionAnalysis[] => {
  const normalizedText = language.startsWith('pl')
    ? normalizePolishText(text.toLowerCase())
    : text.toLowerCase();
  const words = normalizedText.split(/\s+/);
  const lists = emotionWords[language.startsWith('pl') ? 'pl' : 'en'];

  const emotionCounts = Object.entries(lists).map(([emotion, wordList]) => {
    const count = words.filter(word => wordList.includes(word)).length;
    return { emotion, count };
  });

  const totalEmotions = emotionCounts.reduce((sum, { count }) => sum + count, 0) || 1;

  return emotionCounts
    .map(({ emotion, count }) => ({
      emotion,
      count,
      percentage: (count / totalEmotions) * 100
    }))
    .sort((a, b) => b.count - a.count)
    .slice(0, 5); // Get top 5
};