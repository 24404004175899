import { format, parseISO, isAfter, subDays } from 'date-fns';
import { supabase } from '../lib/supabase';
import type { WritingEntry } from '../lib/supabase';

export interface UserSettings {
  dailyGoal: number;
}

const DEFAULT_SETTINGS: UserSettings = {
  dailyGoal: 250
};

const getStorageKey = (userId?: string | null) => {
  return userId ? `writing-${userId}` : 'writing-local';
};

export const saveEntry = async (text: string, duration: number, mood?: string, prompt?: string, userId?: string | null) => {
  const date = format(new Date(), 'yyyy-MM-dd');
  const wordCount = text.trim().split(/\s+/).filter(word => word.length > 0).length;
  const settings = getUserSettings(userId);

  if (userId) {
    try {
      // First try to get existing entry
      const { data: existingEntry, error: fetchError } = await supabase
        .from('entries')
        .select('id')
        .eq('user_id', userId)
        .eq('date', date)
        .maybeSingle();

      if (fetchError) throw fetchError;

      if (existingEntry) {
        // Update existing entry
        const { error: updateError } = await supabase
          .from('entries')
          .update({
            text,
            word_count: wordCount,
            duration,
            mood,
            prompt,
            daily_goal: settings.dailyGoal
          })
          .eq('id', existingEntry.id);

        if (updateError) throw updateError;
      } else {
        // Insert new entry
        const { error: insertError } = await supabase
          .from('entries')
          .insert({
            user_id: userId,
            date,
            text,
            word_count: wordCount,
            duration,
            mood,
            prompt,
            daily_goal: settings.dailyGoal
          });

        if (insertError) throw insertError;
      }
    } catch (error) {
      console.error('Failed to save entry to Supabase:', error);
      // Fallback to local storage
      saveToLocalStorage(date, text, wordCount, duration, mood, prompt, settings.dailyGoal);
    }
  } else {
    // Save to local storage
    saveToLocalStorage(date, text, wordCount, duration, mood, prompt, settings.dailyGoal);
  }
};

const saveToLocalStorage = (date: string, text: string, wordCount: number, duration: number, mood?: string, prompt?: string, dailyGoal: number = DEFAULT_SETTINGS.dailyGoal) => {
  try {
    localStorage.setItem(`writing-${date}`, JSON.stringify({
      date,
      text,
      word_count: wordCount,
      duration,
      mood,
      prompt,
      daily_goal: dailyGoal
    }));
  } catch (error) {
    console.error('Failed to save entry to local storage:', error);
  }
};

export const getEntry = async (date: string, userId?: string | null): Promise<WritingEntry | null> => {
  if (userId) {
    try {
      const { data, error } = await supabase
        .from('entries')
        .select('*')
        .eq('user_id', userId)
        .eq('date', date)
        .maybeSingle();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Failed to get entry from Supabase:', error);
      // Fallback to local storage
      return getFromLocalStorage(date);
    }
  }

  return getFromLocalStorage(date);
};

const getFromLocalStorage = (date: string): WritingEntry | null => {
  try {
    const entry = localStorage.getItem(`writing-${date}`);
    return entry ? JSON.parse(entry) : null;
  } catch (error) {
    console.error('Failed to get entry from local storage:', error);
    return null;
  }
};

export const getTodayEntry = async (userId?: string | null): Promise<WritingEntry | null> => {
  const date = format(new Date(), 'yyyy-MM-dd');
  return getEntry(date, userId);
};

export const getAllEntries = async (userId?: string | null): Promise<WritingEntry[]> => {
  if (userId) {
    try {
      const { data, error } = await supabase
        .from('entries')
        .select('*')
        .eq('user_id', userId)
        .order('date', { ascending: false });

      if (error) throw error;
      return data || [];
    } catch (error) {
      console.error('Failed to get entries from Supabase:', error);
      // Fallback to local storage
      return getAllFromLocalStorage();
    }
  }

  return getAllFromLocalStorage();
};

const getAllFromLocalStorage = (): WritingEntry[] => {
  try {
    const entries: WritingEntry[] = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key?.startsWith('writing-')) {
        const entry = localStorage.getItem(key);
        if (entry) {
          entries.push(JSON.parse(entry));
        }
      }
    }
    return entries.sort((a, b) => (isAfter(parseISO(a.date), parseISO(b.date)) ? -1 : 1));
  } catch (error) {
    console.error('Failed to get entries from local storage:', error);
    return [];
  }
};

export const getCurrentStreak = async (userId?: string | null): Promise<number> => {
  const entries = await getAllEntries(userId);
  let streak = 0;
  let currentDate = new Date();
  
  while (true) {
    const dateStr = format(currentDate, 'yyyy-MM-dd');
    const entry = entries.find(e => e.date === dateStr);
    
    if (!entry || entry.word_count < entry.daily_goal) {
      break;
    }
    
    streak++;
    currentDate = subDays(currentDate, 1);
  }
  
  return streak;
};

export const getUserSettings = (userId?: string | null): UserSettings => {
  const key = `settings-${getStorageKey(userId)}`;
  try {
    const settings = localStorage.getItem(key);
    return settings ? JSON.parse(settings) : DEFAULT_SETTINGS;
  } catch (error) {
    console.error('Failed to get settings:', error);
    return DEFAULT_SETTINGS;
  }
};

export const saveUserSettings = async (settings: UserSettings, userId?: string | null) => {
  const key = `settings-${getStorageKey(userId)}`;
  try {
    localStorage.setItem(key, JSON.stringify(settings));
    
    if (userId) {
      // Also save to Supabase if user is logged in
      const { error } = await supabase
        .from('user_settings')
        .upsert({
          user_id: userId,
          daily_goal: settings.dailyGoal
        });

      if (error) {
        console.error('Failed to save settings to Supabase:', error);
      }
    }
  } catch (error) {
    console.error('Failed to save settings:', error);
  }
};