import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { format, subDays } from 'date-fns';
import { pl, enUS } from 'date-fns/locale';
import { getAllEntries } from '../utils/storage';
import { Trophy, Target, TrendingUp, Clock, Award } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import type { WritingEntry } from '../lib/supabase';

export default function WritingInsights() {
  const { t, i18n } = useTranslation();
  const dateLocale = i18n.language.startsWith('pl') ? pl : enUS;
  const [entries, setEntries] = useState<WritingEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const fetchEntries = async () => {
      try {
        const data = await getAllEntries(user?.id);
        setEntries(data);
      } catch (error) {
        console.error('Error fetching entries:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEntries();
  }, [user?.id]);

  // Calculate last 7 days of writing data
  const last7Days = Array.from({ length: 7 }, (_, i) => {
    const date = format(subDays(new Date(), i), 'yyyy-MM-dd');
    const entry = entries.find(e => e.date === date);
    return {
      date,
      words: entry?.word_count || 0,
      goal: entry?.daily_goal || 250
    };
  }).reverse();

  // Calculate statistics
  const totalWords = entries.reduce((sum, entry) => sum + (entry.word_count || 0), 0);
  const averageWordsPerDay = Math.round(totalWords / (entries.length || 1));
  const longestStreak = entries.reduce((streak, entry) => {
    return (entry.word_count || 0) >= (entry.daily_goal || 250) ? streak + 1 : 0;
  }, 0);
  const totalWritingTime = entries.reduce((sum, entry) => sum + (entry.duration || 0), 0);
  const averageWritingTime = Math.round(totalWritingTime / (entries.length || 1));

  const StatCard = ({ icon: Icon, title, value, description }: {
    icon: typeof Trophy;
    title: string;
    value: string | number;
    description: string;
  }) => (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex items-center gap-4">
        <div className="p-3 bg-emerald-100 rounded-lg">
          <Icon className="h-6 w-6 text-emerald-600" />
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-600">{title}</h3>
          <p className="text-2xl font-bold text-gray-900">{value}</p>
          <p className="text-sm text-gray-500">{description}</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold text-gray-900 mb-6">{t('insights.title')}</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <StatCard
          icon={Trophy}
          title={t('insights.totalWords')}
          value={totalWords.toLocaleString()}
          description={t('insights.wordsWritten')}
        />
        <StatCard
          icon={Target}
          title={t('insights.averageWords')}
          value={averageWordsPerDay.toLocaleString()}
          description={t('insights.wordsPerDay')}
        />
        <StatCard
          icon={TrendingUp}
          title={t('insights.longestStreak')}
          value={longestStreak}
          description={t('insights.daysInRow')}
        />
        <StatCard
          icon={Clock}
          title={t('insights.avgWritingTime')}
          value={`${Math.floor(averageWritingTime / 60)}:${(averageWritingTime % 60).toString().padStart(2, '0')}`}
          description={t('insights.minutesPerDay')}
        />
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-4">{t('insights.weeklyProgress')}</h3>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={last7Days}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="date" 
                tickFormatter={(date) => format(new Date(date), 'd MMM', { locale: dateLocale })}
              />
              <YAxis />
              <Tooltip 
                labelFormatter={(date) => format(new Date(date), 'PP', { locale: dateLocale })}
                formatter={(value: number) => [value, t('insights.words')]}
              />
              <Line 
                type="monotone" 
                dataKey="words" 
                stroke="#10b981" 
                name={t('insights.wordsWritten')} 
                strokeWidth={2}
              />
              <Line 
                type="monotone" 
                dataKey="goal" 
                stroke="#6b7280" 
                strokeDasharray="5 5" 
                name={t('insights.dailyGoal')}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}