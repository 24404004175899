import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PenLine, History, LineChart, BookOpen, UserCircle, Award, Menu, X, Settings } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext';
import AuthModal from './AuthModal';
import SignUpModal from './SignUpModal';
import SettingsModal from './SettingsModal';

interface HeaderProps {
  currentPage: 'write' | 'history' | 'insights' | 'badges' | 'articles';
  onPageChange: (page: 'write' | 'history' | 'insights' | 'badges' | 'articles') => void;
}

export default function Header({ currentPage, onPageChange }: HeaderProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { user, signOut } = useAuth();
  const [showAuth, setShowAuth] = React.useState(false);
  const [showSignUp, setShowSignUp] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState(false);

  const handleNavigation = (page: 'write' | 'history' | 'insights' | 'badges' | 'articles') => {
    onPageChange(page);
    navigate(page === 'write' ? '/' : `/${page}`);
    setIsMenuOpen(false);
  };

  const handleAuthClick = async () => {
    if (user) {
      await signOut();
    } else {
      setShowAuth(true);
    }
    setIsMenuOpen(false);
  };

  const NavButton = ({ page, icon: Icon, label }: { 
    page: 'write' | 'history' | 'insights' | 'badges' | 'articles',
    icon: typeof PenLine,
    label: string 
  }) => (
    <button
      onClick={() => handleNavigation(page)}
      className={`flex items-center gap-2 px-3 py-2 rounded-md text-sm font-medium w-full ${
        currentPage === page
          ? 'text-gray-900 bg-gray-100'
          : 'text-gray-500 hover:text-gray-900 hover:bg-gray-100'
      }`}
    >
      <Icon size={18} />
      {label}
    </button>
  );

  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center gap-2 flex-shrink-0">
            <button
              onClick={() => handleNavigation('write')}
              className="flex items-center gap-2 hover:opacity-80 transition-opacity"
            >
              <PenLine className="h-8 w-8 text-emerald-600" />
              <span className="text-xl font-bold text-gray-900">{t('header.title')}</span>
            </button>
          </div>
          
          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-4">
            <NavButton page="write" icon={PenLine} label={t('header.write')} />
            <NavButton page="history" icon={History} label={t('header.history')} />
            <NavButton page="insights" icon={LineChart} label={t('header.insights')} />
            <NavButton page="badges" icon={Award} label={t('header.badges')} />
            <NavButton page="articles" icon={BookOpen} label={t('header.articles')} />
          </nav>

          {/* Desktop Auth Buttons */}
          <div className="hidden md:flex items-center space-x-2">
            {!user && (
              <>
                <button
                  onClick={() => setShowSignUp(true)}
                  className="flex items-center gap-2 px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
                >
                  {t('auth.signUp')}
                </button>
              </>
            )}
            <button
              onClick={() => setShowSettings(true)}
              className="flex items-center gap-2 px-4 py-2 bg-white border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
            >
              <Settings size={18} />
              {t('editor.settings')}
            </button>
            <button
              onClick={handleAuthClick}
              className="flex items-center gap-2 px-4 py-2 bg-white border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
            >
              <UserCircle size={18} />
              {user ? t('auth.signOut') : t('auth.signIn')}
            </button>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden p-2 rounded-md text-gray-500 hover:text-gray-900 hover:bg-gray-100"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden py-4 border-t border-gray-200">
            <nav className="flex flex-col space-y-2">
              <NavButton page="write" icon={PenLine} label={t('header.write')} />
              <NavButton page="history" icon={History} label={t('header.history')} />
              <NavButton page="insights" icon={LineChart} label={t('header.insights')} />
              <NavButton page="badges" icon={Award} label={t('header.badges')} />
              <NavButton page="articles" icon={BookOpen} label={t('header.articles')} />
            </nav>
            <div className="mt-4 space-y-2">
              {!user && (
                <button
                  onClick={() => setShowSignUp(true)}
                  className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
                >
                  {t('auth.signUp')}
                </button>
              )}
              <button
                onClick={() => setShowSettings(true)}
                className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-white border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <Settings size={18} /> {t('editor.settings')}
              </button>
              <button
                onClick={handleAuthClick}
                className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-white border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <UserCircle size={18} />
                {user ? t('auth.signOut') : t('auth.signIn')}
              </button>
            </div>
          </div>
        )}
      </div>

      <AuthModal
        isOpen={showAuth}
        onClose={() => setShowAuth(false)}
      />
      <SignUpModal
        isOpen={showSignUp}
        onClose={() => setShowSignUp(false)}
        onSignInClick={() => {
          setShowSignUp(false);
          setShowAuth(true);
        }}
      />
      <SettingsModal
        isOpen={showSettings}
        onClose={() => setShowSettings(false)}
      />
    </header>
  );
}