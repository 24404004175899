import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { X, Brain, Clock, Timer, Lightbulb, Share2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useTextAnalysis } from '../hooks/useTextAnalysis';
import CategoryChart from './CategoryChart';
import EmotionsChart from './EmotionsChart';
import { analyzeCategoryWords } from '../utils/categoryAnalysis';
import { analyzeEmotions } from '../utils/emotionsAnalysis';

const EXCLUDED_CATEGORIES = [
  'future', 'past', 'analytical', 'creative', 'taste', 'touch', 'sight', 'hearing', 'smell'
];

const StatSlider = ({ 
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  leftLabel,
  rightLabel,
  percentage,
  leftColor = "emerald",
  rightColor = "blue",
  gradientClasses
}: { 
  leftIcon: typeof Lightbulb;
  rightIcon: typeof Brain;
  leftLabel: string;
  rightLabel: string;
  percentage: number;
  leftColor?: string;
  rightColor?: string;
  gradientClasses?: string;
}) => {
  const leftPercentage = 100 - percentage;
  const position = `${percentage}%`;

  return (
    <div className="mb-8">
      <div className="flex justify-between text-sm text-gray-600 mb-2">
        <div className="flex items-center gap-2">
          <LeftIcon className={`h-4 w-4 text-${leftColor}-500`} />
          <span>{leftLabel}</span>
        </div>
        <div className="flex items-center gap-2">
          <RightIcon className={`h-4 w-4 text-${rightColor}-500`} />
          <span>{rightLabel}</span>
        </div>
      </div>
      <div className={`h-3 ${gradientClasses} rounded-full relative`}>
        <div 
          className="absolute top-1/2 -translate-y-1/2 w-4 h-4 bg-white rounded-full shadow-lg border-2 border-gray-200 cursor-pointer transition-all duration-300"
          style={{ left: position }}
        />
      </div>
      <div className="flex justify-between mt-2 text-xs text-gray-500">
        <span>{leftPercentage.toFixed(0)}%</span>
        <span>{percentage.toFixed(0)}%</span>
      </div>
    </div>
  );
};

const SensesChart = ({ senses }: { senses: Record<string, number> }) => {
  const { t } = useTranslation();
  const colors = {
    sight: 'bg-violet-500',
    smell: 'bg-emerald-500',
    taste: 'bg-amber-500',
    hearing: 'bg-blue-500',
    touch: 'bg-rose-500'
  };

  return (
    <div className="space-y-3">
      <h3 className="font-medium text-gray-900 mb-4">{t('stats.senses')}</h3>
      {Object.entries(senses).map(([sense, percentage]) => (
        <div key={sense} className="space-y-2">
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">{t(`stats.types.${sense}`)}</span>
            <span className="text-gray-500">{percentage.toFixed(0)}%</span>
          </div>
          <div className="h-2 bg-gray-200 rounded-full">
            <div
              className={`h-2 rounded-full ${colors[sense as keyof typeof colors]}`}
              style={{ width: `${percentage}%` }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default function Stats() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { text = '', duration = 0 } = location.state || {};
  const [categoryStats, setCategoryStats] = useState([]);
  const [emotionStats, setEmotionStats] = useState([]);
  
  const { stats, wordsPerMinute } = useTextAnalysis(
    text,
    i18n.language,
    duration,
    true
  );

  const fetchAnalysis = useCallback(async () => {
    if (text) {
      const [categories, emotions] = await Promise.all([
        analyzeCategoryWords(text, i18n.language).then(cats => 
          cats
            .filter(cat => !EXCLUDED_CATEGORIES.includes(cat.category.toLowerCase()))
            .slice(0, 3)
        ),
        analyzeEmotions(text, i18n.language)
      ]);
      setCategoryStats(categories);
      setEmotionStats(emotions);
    }
  }, [text, i18n.language]);

  useEffect(() => {
    fetchAnalysis();
  }, [fetchAnalysis]);

  if (!text) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="text-center py-12 text-gray-500">
          {t('stats.noText')}
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-5xl mx-auto px-4 py-8">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-4">
          <h2 className="text-2xl font-bold text-gray-900">{t('stats.title')}</h2>
          <button
            onClick={() => {
              const url = encodeURIComponent(window.location.href);
              const title = encodeURIComponent('My Writing Stats on Wordenio');
              window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${title}`,
                'facebook-share-dialog',
                'width=800,height=600'
              );
            }}
            className="flex items-center gap-2 px-4 py-2 bg-[#1877F2] text-white rounded-lg hover:bg-[#166FE5] transition-colors"
          >
            <Share2 size={18} />
            {t('Udostępnij na Facebooku')}
          </button>
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={() => navigate('/')}
            className="p-2 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100"
          >
            <X size={20} />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div>
          <div className="mb-8 p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-2 text-gray-600 mb-2">
              <Timer size={18} />
              <span className="font-medium">{t('stats.writingSpeed')}</span>
            </div>
            <div className="text-2xl font-bold text-gray-900">
              {t('stats.wordsPerMinute', { count: wordsPerMinute })}
            </div>
          </div>

          <StatSlider
            leftIcon={Lightbulb}
            rightIcon={Brain}
            leftLabel={t('stats.types.creative')}
            rightLabel={t('stats.types.analytical')}
            percentage={stats.mindset.type === 'analytical' ? stats.mindset.percentage : 100 - stats.mindset.percentage}
            leftColor="emerald"
            rightColor="blue"
            gradientClasses="bg-gradient-to-r from-emerald-500 to-blue-500"
          />

          <StatSlider
            leftIcon={Clock}
            rightIcon={Clock}
            leftLabel={t('stats.types.pastReflective')}
            rightLabel={t('stats.types.futureFocused')}
            percentage={stats.timeOrientation.type === 'futureFocused' ? stats.timeOrientation.percentage : 100 - stats.timeOrientation.percentage}
            leftColor="orange"
            rightColor="cyan"
            gradientClasses="bg-gradient-to-r from-orange-500 to-cyan-500"
          />

          <div className="mt-8 pt-8 border-t border-gray-200">
            <SensesChart senses={stats.senses} />
          </div>
        </div>
      
        <div className="h-full flex flex-col space-y-8">
          <div className="flex-1">
            <CategoryChart data={categoryStats} />
            <EmotionsChart data={emotionStats} />
          </div>
        </div>
      </div>
    </div>
  );
}