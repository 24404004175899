import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseKey) {
  throw new Error('Missing Supabase credentials');
}

export const supabase = createClient(supabaseUrl, supabaseKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true
  }
});

export interface BlogPost {
  id: string;
  title: string;
  content: string;
  created_at: string;
  published: boolean;
  author: string;
  user_id?: string;
}

export interface WritingEntry {
  id: string;
  user_id: string;
  date: string;
  text: string;
  word_count: number;
  duration: number;
  mood?: string;
  prompt?: string;
  daily_goal: number;
  created_at: string;
}

export interface Prompt {
  id: string;
  text: string;
  language: string;
  created_at: string;
}

export interface WordList {
  id: string;
  language: string;
  category: string;
  words: string[];
  created_at: string;
}

export const getWordLists = async (language: string): Promise<Record<string, string[]>> => {
  try {
    const { data, error } = await supabase
      .from('word_lists')
      .select('category, words')
      .eq('language', language);

    if (error) throw error;

    return data.reduce((acc, { category, words }) => {
      acc[category] = words;
      return acc;
    }, {} as Record<string, string[]>);
  } catch (error) {
    console.error('Error fetching word lists:', error);
    return {};
  }
};