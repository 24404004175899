import { supabase } from '../lib/supabase';

export const normalizePolishText = (text: string): string => {
  return text
    .toLowerCase()
    .replace(/[ąą]/g, 'a')
    .replace(/[ćč]/g, 'c')
    .replace(/[ęę]/g, 'e')
    .replace(/[łł]/g, 'l')
    .replace(/[ńň]/g, 'n')
    .replace(/[óó]/g, 'o')
    .replace(/[śš]/g, 's')
    .replace(/[źž]/g, 'z')
    .replace(/[żż]/g, 'z');
};

export interface Analysis {
  mindset: { type: string; percentage: number };
  emotion: { type: string; percentage: number };
  timeOrientation: { type: string; percentage: number };
  senses: Record<string, number>;
}

export const analyzeText = async (text: string, language: string): Promise<Analysis> => {
  const normalizedText = language.startsWith('pl')
    ? normalizePolishText(text.toLowerCase())
    : text.toLowerCase();
  const words = normalizedText.split(/\s+/);
  
  const { data: wordLists, error } = await supabase
    .from('word_lists')
    .select('category, words')
    .eq('language', language.startsWith('pl') ? 'pl' : 'en');

  if (error) {
    console.error('Error fetching word lists:', error);
    return {
      mindset: { type: 'balanced', percentage: 50 },
      emotion: { type: 'balanced', percentage: 50 },
      timeOrientation: { type: 'balanced', percentage: 50 },
      senses: { sight: 20, smell: 20, taste: 20, hearing: 20, touch: 20 }
    };
  }

  const lists = wordLists.reduce((acc, { category, words }) => {
    acc[category] = words;
    return acc;
  }, {} as Record<string, string[]>);

  const countWords = (wordList: string[]) =>
    words.filter((w) => wordList?.includes(w)).length || 0;

  const analyticalCount = countWords(lists.analytical);
  const creativeCount = countWords(lists.creative);
  const totalMindset = analyticalCount + creativeCount;

  const positiveCount = countWords(lists.positive);
  const negativeCount = countWords(lists.negative);
  const totalEmotion = positiveCount + negativeCount;

  const pastCount = countWords(lists.past);
  const futureCount = countWords(lists.future);
  const totalTime = pastCount + futureCount;

  const senses = {
    sight: countWords(lists.sight),
    smell: countWords(lists.smell),
    taste: countWords(lists.taste),
    hearing: countWords(lists.hearing),
    touch: countWords(lists.touch),
  };

  const totalSenses = Object.values(senses).reduce((a, b) => a + b, 0) || 1;

  // Convert sense counts to percentages
  const sensePercentages = Object.entries(senses).reduce(
    (acc, [sense, count]) => {
      acc[sense] = (count / totalSenses) * 100;
      return acc;
    },
    {} as Record<string, number>
  );

  return {
    mindset: {
      type:
        totalMindset === 0
          ? 'balanced'
          : analyticalCount > creativeCount
          ? 'analytical'
          : 'creative',
      percentage:
        totalMindset === 0
          ? 50
          : (Math.max(analyticalCount, creativeCount) / totalMindset) * 100,
    },
    emotion: {
      type:
        totalEmotion === 0
          ? 'balanced'
          : positiveCount > negativeCount
          ? 'positive'
          : 'contemplative',
      percentage:
        totalEmotion === 0
          ? 50
          : (Math.max(positiveCount, negativeCount) / totalEmotion) * 100,
    },
    timeOrientation: {
      type:
        totalTime === 0
          ? 'balanced'
          : futureCount > pastCount
          ? 'futureFocused'
          : 'pastReflective',
      percentage:
        totalTime === 0
          ? 50
          : (Math.max(pastCount, futureCount) / totalTime) * 100,
    },
    senses: sensePercentages,
  };
};
